//@ts-nocheck
"use client"
import React, { FC, useState, useEffect } from "react";
import ym from 'react-yandex-metrika';
import { Field, Formik, Form } from "formik";
import InputMask from "react-input-mask";
import * as Yup from "yup";
import {
    Col,
    Container,
    Row,
    Button,
    FormCheck,
    FormGroup,
    FormLabel,
    FormControl,
    Spinner
} from "react-bootstrap";
import MaskedInput from 'react-text-mask';
import classes from "./FeedbackForm.module.css";
import AlertInfo from "../../ui/alert/AlertInfo";
import Utils from "../../../../utils/all";


import { useLandingContext } from "../../../../context/LandingProvider";
import { api } from "../../../../services/api/Api";
import { helpers } from "../../../../services/_helpers/Helpers";
import Valid from "../../../../utils/valid";

interface IProps { };

export const FeedbackForm: FC<IProps> = (props) => {
    const { landing, notify, theme } = useLandingContext();
    const [disabled, setDisabled] = useState<boolean>(false);
    const [isSend, setIsSend] = useState<boolean>(false);
    const [alertInfo, setAlertInfo] = useState<string>(null);
    const [isWindowLoaded, setIsWindowLoaded] = useState<boolean>(false);

    const validationSchema = Yup.object().shape({
        firstName: Yup.string()
            .required("Заполните это поле"),
        email: Yup.string()
            .email("Введен недействительный адрес электронной почты")
            .required("Заполните это поле"),
        phone: Yup.string()
            .required("Заполните это поле")
            .matches(/^\+7\s?\(?\d{3}\)?\s?\d{3}[-\s]?\d{2}[-\s]?\d{2}$/, "Введите корректный номер"),

    });

    const handleSubmit = async (e, values) => {
        e.preventDefault();
        setIsSend(true);
        const alertText = notify.formContact && values.name ? Utils.createStringFromTemplate(notify.formContact, { fName: values.name }) : "";
        const query = {
            name: values.name,
            email: values.email,
            phone: values.phone,
            message: values.message,
            site: {
                _id: landing._id,
                link: "sites",
                display: landing.domain
            }
        };
        const result = await api.cms.save("contacts", query);
        if (result) {
            const html = `
                            <p><strong>Дата: </strong>${helpers.date.today()}</p>
                            <p><strong>Имя: </strong>${values.name}</p>
                            <p><strong>Email: </strong>${values.email}</p>
                            <p><strong>Телефон: </strong>${values.phone}</p>
                            <p><strong>Сообщение: </strong>${values.message}</p>
                            <p><strong>Сайт: </strong>${landing.title}</p>
                        `;

            const mail = {
                to: [theme.email, 'testcrm@bag-travels.ru'],
                subject: `Обратная связь - ${landing.domain}`,
                html: html,
            };
            const send = await api.mail.send(landing.domain, mail);
            if (send) {
                setAlertInfo(alertText);
                if (theme?.yagoals?.feedbackForm) {
                    // ym('reachGoal', 'feedbackForm');
                }
                setIsSend(false);
            }
        }

    };
    useEffect(() => {
        // Устанавливаем состояние, когда компонент смонтирован
        setIsWindowLoaded(true);
    }, []);

    if (!isWindowLoaded) return null;
    return (
        <div className={classes.mainBox} suppressHydrationWarning={true}>
            <Formik
                initialValues={{
                    name: "",
                    email: "",
                    phone: "",
                    message: "",
                }}
                validationSchema={validationSchema}
                onSubmit={() => { }}
            >
                {({
                    values,
                    errors,
                    handleChange,
                    handleBlur,
                    touched,
                    setFieldValue,
                    setFieldTouched,
                    isSubmitting,
                    isValid,
                }) => (
                    <Form
                        onSubmit={(e) => handleSubmit(e, values)}
                    >
                        {alertInfo &&
                            <Row className="my-3">
                                <Col xs={12}>
                                    <AlertInfo
                                        show
                                        hide
                                        type={notify.formContactColor ? notify.formContactColor : "success"}
                                        hideTime={11000}
                                    >
                                        {alertInfo}
                                    </AlertInfo>
                                </Col>
                            </Row>
                        }
                        <Row>
                            <Col xs="12">
                                <h6 suppressHydrationWarning={true} className="text-center">Форма обратной связи</h6>
                            </Col>
                            <Col xs="12">
                                <FormGroup className="form-group mb-3 required">
                                    <FormLabel
                                        className="text-right text-dark control-label">
                                        Ваше Имя
                                    </FormLabel>
                                    <FormControl
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        required
                                        size="sm" name="name" type="text"
                                        value={values.name}
                                        className={`${touched.name && errors.name ? 'is-invalid' : ''}`}
                                    />
                                    {touched.name && errors.name && (
                                        <div className="invalid-feedback d-block fs-5">{errors.name}</div>
                                    )}
                                </FormGroup>
                            </Col>
                            <Col xs="12">
                                <FormGroup className="form-group mb-3 required">
                                    <FormLabel
                                        className="text-right text-dark control-label">
                                        Ваш Email
                                    </FormLabel>
                                    <FormControl
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        required
                                        size="sm" name="email" type="text"
                                        value={values.email}
                                        className={`${touched.email && errors.email ? 'is-invalid' : ''}`}
                                    />
                                    {touched.email && errors.email && (
                                        <div className="invalid-feedback d-block fs-5">{errors.email}</div>
                                    )}
                                </FormGroup>
                            </Col>
                            <Col xs="12">
                                <FormGroup className="form-group mb-3 required">
                                    <FormLabel className="text-right text-dark control-label">
                                        Ваш Телефон
                                    </FormLabel>
                                    <Field name="phone">
                                        {({ field }) => (
                                            <MaskedInput
                                                {...field}
                                                mask={helpers.form.phoneMask()}
                                                placeholder="+7 (___) ___-__-__"
                                                type="text"
                                                required
                                                className={`form-control ${touched.phone && errors.phone ? "is-invalid" : ""}`}
                                                onChange={(e) => setFieldValue("phone", e.target.value)}
                                                onBlur={() => setFieldTouched("phone", true)}
                                                onInvalid={(e) => {
                                                    e.preventDefault();
                                                    setFieldTouched("phone", true);
                                                }}
                                            />
                                        )}
                                    </Field>
                                    {touched.phone && errors.phone && (
                                        <div className="invalid-feedback d-block fs-5">{errors.phone}</div>
                                    )}
                                </FormGroup>
                            </Col>
                            <Col xs="12">
                                <FormGroup className="mb-3">
                                    <FormLabel
                                        className="text-right text-dark form-label">
                                        Примечание
                                    </FormLabel>
                                    <FormControl
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        name="message"
                                        as="textarea" rows={3}
                                        value={values.message}
                                    />
                                </FormGroup>
                            </Col>
                            <Col xs="12">
                                <FormGroup as={Row} className="mb-3 required">
                                    {/* @ts-ignore */}
                                    <Col xs={12}>
                                        {/* @ts-ignore */}
                                        <FormCheck type="checkbox">
                                            <FormCheck.Input
                                                required
                                                type="checkbox"
                                            />
                                            <FormCheck.Label className="text-dark control-label">Подтверждаю
                                                своё согласие на передачу в электронном виде регистрационной
                                                формы, а так же на обработку и хранение информации (в том
                                                числе персональных данных)</FormCheck.Label>
                                        </FormCheck>
                                    </Col>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button
                                    variant="primary"
                                    type="submit"
                                    disabled={disabled}
                                // disabled={!isValid || isSubmitting}
                                >
                                    {isSend &&
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                    }
                                    Отправить
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                )}
            </Formik>
        </div>
    )
};
